import { COOKIE_NAME } from "common/constants";

/**
 * Creates a dataLayer push to GTM with event detail, based if
 * cookies has been accepted
 *
 * @param {string} event - event to send
 * @param {object} detail - object with details to send
 */
export const dataLayerPush = (event, detail) => {
    if (!window.dataLayer) {
        return;
    }

    const cookiesAccepted = localStorage.getItem(COOKIE_NAME);

    if (cookiesAccepted && cookiesAccepted === 'true') {
        window.dataLayer.push({
            event,
            detail
        });
    }
};
