import { createSlice } from '@reduxjs/toolkit';
import { BREAKPOINTS } from 'common/constants';
import { dataLayerPush } from 'common/helpers/datalayer-push';

const deviceWidth = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
);
const deviceHeight = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
);

/**
 * @constant {Slice} uiSlice - Creates a slice of the redux store to handle
 * various bits of ui state
 */
export const uiSlice = createSlice({
    name: 'ui',
    /**
     * @constant {object} initialState - Setup the starting values for the store
     */
    initialState: {
        isMobile: window.innerWidth < BREAKPOINTS.MOBILE,
        isDesktop: window.innerWidth > BREAKPOINTS.TABLET,
        isDesktopSmall: window.innerWidth > BREAKPOINTS.DESKTOP_SMALL,
        orientation: deviceWidth > deviceHeight ? 'Landscape' : 'Portrait',
        moodIndex: 0,
        selectedPromo: {},
        navSticky: false,
        pageTrackingCampaignName: '',
        scrollPosition: document.querySelector('html').scrollTop
    },
    reducers: {
        /**
         * Update the page's tracking campaign name
         *
         * @param {object} state - The current state of the store
         * @param {object} action - The action object, the payload should be a
         */
        setPageTrackingCampaignName: (state, action) => {
            state.pageTrackingCampaignName = action.payload;
        },
        /**
         * Update whether the browser is desktop or not
         *
         * @param {object} state - The current state of the store
         * @param {object} action - The action object, the payload should be a
         */
        setIsMobile: (state, action) => {
            state.isMobile = action.payload;
        },
        /**
         * Update whether the browser is desktop or not
         *
         * @param {object} state - The current state of the store
         * @param {object} action - The action object, the payload should be a
         */
        setIsDesktop: (state, action) => {
            state.isDesktop = action.payload;
        },
        /**
         * Update whether the browser is desktop small or not
         *
         * @param {object} state - The current state of the store
         * @param {object} action - The action object, the payload should be a
         */
        setIsDesktopSmall: (state, action) => {
            state.isDesktopSmall = action.payload;
        },
        /**
         * Update with current scroll position
         *
         * @param {object} state - The current state of the store
         * @param {object} action - The action object, the payload should be a
         */
        setScrollPosition: (state, action) => {
            state.scrollPosition = action.payload;
        },
        /**
         * Update with current selected promo
         *
         * @param {object} state - The current state of the store
         * @param {object} action - The action object, the payload should be a
         */
        setSelectedPromo: (state, action) => {
            state.selectedPromo = action.payload;
        },
        /**
         * Update with current map markers
         *
         * @param {object} state - The current state of the store
         * @param {object} action - The action object, the payload should be a
         */
        setMoodIndex: ( state, action ) => {
            state.moodIndex = action.payload;
        },
        /**
         * triggers a dataLayer push when the device orientation changes
         *
         * @param {object} state - The current state of the store
         * @param {object} action - The action object, the payload should be a
         */
        setOrientation: (state, action) => {
            dataLayerPush('orientation', {
                orientation_change: action.payload
            });
            state.orientation = action.payload;
        },
        /**
         * Update if we should stick the nav bar to the top for the browser
         *
         * @param {object} state - The current state of the store
         * @param {object} action - The action object, the payload should be a
         */
        setNavSticky: (state, action) => {
            document.body.dataset.sticky = action.payload;
            state.navSticky = action.payload;
        }
    }
});

export const {
    // eslint-disable-next-line id-length
    setPageTrackingCampaignName,
    setOrientation,
    setIsMobile,
    setIsDesktop,
    setIsDesktopSmall,
    setScrollPosition,
    setSelectedPromo,
    setMoodIndex,
    setNavSticky
} = uiSlice.actions;

export default uiSlice.reducer;
