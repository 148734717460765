import debounce from 'lodash/debounce';
import store from 'common/store/store';
import {
    setIsDesktop,
    setIsDesktopSmall,
    setOrientation,
    setNavSticky
} from 'common/store/ui-slice';
import { CLASSES } from 'common/constants';

import { BREAKPOINTS } from 'common/constants';

/**
 *
 */
export function setupPage() {
    // We listen to the resize event so we update our dimensions
    window.addEventListener(
        'resize',
        debounce( () => {
            const deviceWidth = Math.max(
                document.documentElement.clientWidth,
                window.innerWidth || 0
            );
            const deviceHeight = Math.max(
                document.documentElement.clientHeight,
                window.innerHeight || 0
            );

            store.dispatch(
                setIsDesktop( window.innerWidth > BREAKPOINTS.TABLET )
            );
            store.dispatch(
                setIsDesktopSmall( window.innerWidth > BREAKPOINTS.DESKTOP_SMALL )
            );
            store.dispatch(
                setOrientation(
                    deviceWidth > deviceHeight ? 'Landscape' : 'Portrait'
                )
            );
        } )
    );

    document.addEventListener( 'scroll', () =>
        store.dispatch( setNavSticky( window.scrollY > 0 ) )
    );
}
