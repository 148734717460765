import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import environment from 'common/environment';
import translator from 'common/i18n';
import { getCountryByLang } from 'common/helpers/misc';

const country = getCountryByLang(translator.language);

export const hotelsApi = createApi({
    reducerPath: 'hotelsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${environment.hotelsApi}`
    }),
    endpoints: (builder) => ({
        hotels: builder.query({
            query: ({ hotels }) =>
                `?ids=${hotels}&country=${country}&pos=expedia`,
            transformResponse: (response) => {
                return response.hotels.map((hotel) => {
                    return {
                        ...hotel,
                        checkInDate: response.checkInDate,
                        checkOutDate: response.checkOutDate
                    };
                });
            }
        })
    })
});

export const { useHotelsQuery } = hotelsApi;
