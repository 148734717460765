/** @module content/endpoints */

import { prepareParams } from 'common/helpers/param-helpers';

/**
 * creates content path based on the type and parameters
 *
 * @param {string} type Type of content
 * @param {object} params query params (to be used as GET params)
 * @param {string} lang   the API language; defaults to EN
 * @param {number} id     ID of the item if requesting only one
 * @returns {string} api path with params
 * @example
 * createContentPath( 'video', { tagNames: "highlights" }, 'EN' );
 * // /content/atpmedia/video/EN/?tagNames=highlights
 * createContentPath( 'video', {}, 'EN', 123456 );
 * // /content/atpmedia/video/EN/123456
 */
export function createContentPath( type, params, lang, id ) {
    const contentType = type ? type.toLowerCase() + '/' : '';
    return `/content/expedia-tampa-bay/${ contentType }${ lang ? lang : 'EN' }/${ id ? id : ''
        }${ prepareParams( params ) }`;
}
